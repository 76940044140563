exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ca-js": () => import("./../../../src/pages/ca.js" /* webpackChunkName: "component---src-pages-ca-js" */),
  "component---src-pages-como-trabajamos-js": () => import("./../../../src/pages/como-trabajamos.js" /* webpackChunkName: "component---src-pages-como-trabajamos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-desarrollo-web-js": () => import("./../../../src/pages/proyectos-desarrollo-web.js" /* webpackChunkName: "component---src-pages-proyectos-desarrollo-web-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-technologies-js": () => import("./../../../src/templates/technologies.js" /* webpackChunkName: "component---src-templates-technologies-js" */)
}

