const theme = {
  space: [
    0,
    '0.25rem',
    '0.5rem',
    '1rem',
    '2rem',
    '4rem',
    '8rem',
    '16rem',
  ],
  fonts: {
    body: 'geomanist, sans-serif',
    heading: 'geomanist, sans-serif',
    monospace: 'monospace',
  },
  fontSizes: [
    '0.8rem',
    '1rem',
    '1.25rem',
    '1.563rem',
    '1.953rem',
    '2.441rem',
    '3.052rem',
    '4.768rem',
    '5.96rem',
    '9.313rem',
    '11.642rem',
  ],
  fontWeights: {
    body: 400,
    heading: 200,
    thin: 100,
    extralight: 200,
    light: 300,
    medium: 500,
    book: 600,
    bold: 700,
    black: 800,
    ultra: 900,
  },
  letterSpacings: [
    0,
    '0.5px',
    '1px',
  ],
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    none: 1,
    snug: 1.375,
    relaxed: 1.625,
  },
  colors: {
    text: '#050505',
    background: '#fafafa',
    primary: '#555fa7',
    secondary: '#ff6633',
    tertiary: '#efefdf',
    accent: '#ff6633',
    highlight: '#0c0e18',
    muted: '#f6f6f6',
    white: '#fff',
    black: '#000',
    gray: ['#f0f0f0', '#e6e6e6', '#c2c2c2', '#d3d3d3', '#8e8e8e', '#2c2c2c', '#050505'],
    modes: {
      dark: {
        text: '#f0f0f0',
        background: '#131525',
        primary: '#555fa7',
        secondary: '#ff6633',
        tertiary: '#efefdf',
        gray: ['#1b1b1c', '#252526', '#424244', '#7c7c80', '#86868a', '#a4a4a7', '#d6d6d7'],
      }
    }
  },
  breakpoints: [
    '640px',
    '768px',
    '1024px',
    '1280px',
    '1680px',
  ],
  sizes: {
    px: '1px',
    "0": '0',
    "1": '0.25rem',
    "2": '0.5rem',
    "3": '0.75rem',
    "4": '1rem',
    "5": '1.25rem',
    "6": '1.5rem',
    "8": '2rem',
    "10": '2.5rem',
    "12": '3rem',
    "16": '4rem',
    "20": '5rem',
    "24": '6rem',
    "32": '8rem',
    full: '100%',
    screenHeight: '100vh',
    screenWidth: '100vw',
    phoneMaxWidht: '90vw',
    tabletMaxWidth: '90vw',
    desktopMaxWidth: '90vw',
    desktopLargeMaxWidth: '92vw',
  },
  buttons: {
    primary: {
      color: 'white',
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'primary',
      cursor: 'pointer',
    },
    secondary: {
      color: 'gray.1',
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'secondary',
      cursor: 'pointer',
    },
  },
  images: {
    logo: {
      width: '4vw',
    },
  },
  layout: {
    container: {
      maxWidth: [
        'phoneMaxWidht',
        'phoneMaxWidht',
        'tabletMaxWidth',
        'tabletMaxWidth',
        'desktopMaxWidth',
        'desktopLargeMaxWidth'
      ],
    },
  },
  text: {
    heading: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 2,
    },
    base: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 1.33,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      letterSpacing: 1,
    },
  },
  links: {
    nav: {
      color: 'white',
      fontSize: [0, 1],
      fontWeight: 'light',
      ":hover": {
        color: 'white',
      },
      ":active": {
        color: 'white',
      },
      ":focus": {
        color: 'white',
      },
    }
  },
  styles: {
    root: {
      boxSizing: 'border-box',
      fontSize: 1,
      padding: 0,
      variant: 'text.base',
    },
    h1: {
      variant: 'text.heading',
      fontSize: [5, 6, 8],
      mt: 2,
      mb: 1,
    },
    h2: {
      variant: 'text.heading',
      fontSize: [3, 4, 5],
      mt: 2,
      mb: 1,
    },
    h3: {
      variant: 'text.heading',
      fontSize: [null, null, 3, 4],
      mt: 3,
      mb: 1,
    },
    h4: {
      variant: 'text.heading',
      fontSize: [null, null, 2, 3],
    },
    h5: {
      variant: 'text.heading',
      fontSize: [null, null, null, 2],
    },
    h6: {
      variant: 'text.heading',
      fontSize: 1,
    },
    p: {
      variant: 'text.base',
      fontSize: [null, null, 2, 3],
    },
    a: {
      borderBottom: '1px dotted',
      color: 'primary',
      textDecoration: 'none',
      transition: 'all 0.3s ease-in-out',
      ":hover": {
        borderBottom: '1px solid',
      },
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    },
    hr: {
      bg: 'muted',
      border: 0,
      height: '1px',
      m: 3,
    },
    ul: {
      listStyle: 'square',
      marginLeft: 2,
      paddingLeft: 2,
    },
    li: {
      py: 1,
    }
  }
}

export default theme